import React from 'react';
import Badge from 'Badge';
import { useSelector } from 'react-redux';
import styles from './product-header.module.scss';

function ProductHeader(props) {
   const { colorSelected, gender, sport, featured } = props;
   const theme = useSelector((state) => state.theme.name);
   return (
      <div className={styles.wrapper}>
         <div className={styles.wrapperTop}>
            <div className={styles.genderSport}>
               {gender} | {sport}
            </div>

            {featured && (
               <div className={styles.featured}>
                  <Badge
                     color={featured?.text_color || themes[theme]['color-prod-detail-badge-text-principal']}
                     backgroundColorLeft={featured?.background_color_left || themes[theme]['color-prod-detail-badge-bg-principal']}
                     backgroundColorRight={featured?.background_color_right || themes[theme]['color-prod-detail-badge-bg-principal']}
                     uppercase
                     featureSpecial
                  >
                     {featured.name}
                  </Badge>
               </div>
            )}
         </div>
         <h1 className={styles.name}>
            {colorSelected?.color
               ? `${colorSelected.name} - ${colorSelected.color}`
               : colorSelected?.name}
         </h1>
      </div>
   );
}

export default ProductHeader;
