import React from 'react';
import Image from 'next/image';

function SizeModalShoes() {
   return (
      <>
         <p>1. Medí del talón a la punta del dedo.</p>
         <p>
            2. Colocá un papel en el suelo con uno de los bordes pegado a la pared. Pisá el papel
            con el talón pegado a la pared. Con un lápiz/birome hacé una marca donde llega el dedo
            pulgar.
         </p>
         <p>
            3. En el papel, medí la distancia que marcaste hasta el borde del papel y con esa medida
            en CM vas a poder elegir mirando en la tabla de equivalencias automática que se
            despliega al pasar el cursor sobre el talle argentino. Si estás justo entre dos talles
            te recomendamos que elijas el talle mas grande. Podés probarlo en tu casa y en caso de
            que no te guste tenés 30 días para realizar el cambio gratuitamente.
         </p>
         <div>
            <Image src="/assets/measurefoot.svg" layout="responsive" width={32} height={32} />
         </div>
      </>
   );
}

export default SizeModalShoes;
