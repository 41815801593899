/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Placeholder from 'Placeholder';
import { useSelector } from 'react-redux';
import SizeModal from '../SizeModal';
import SizeItem from './SizeItem';
import styles from './size-list.module.scss';

function SizeList({ productDetail, customSetVariant, variantSelected }) {
   const localProductDetail = productDetail || useSelector((state) => state.productDetail);
   const {
      loading,
      colorSelected: { variants },
      product,
   } = localProductDetail;
   const { business } = product;
   const [modalVisible, setModalVisible] = useState(false);

   const renderSizes = () => {
      return variants.map((x) => (
         <SizeItem
            key={x.id}
            variant={x}
            customSetVariant={customSetVariant}
            productDetail={localProductDetail}
            variantSelected={variantSelected}
         />
      ));
   };

   return (
      <div className={styles.container}>
         <div className={styles.wrapperTitle}>
            <h2 className={styles.title}>Talles</h2>

            {business !== 'Accesorios' && (
               <span
                  className={styles.sizeButton}
                  onClick={() => setModalVisible(true)}
                  role="button"
               >
                  ¿Cuál es mi talle?
               </span>
            )}
         </div>

         {loading && (
            <Placeholder uniqueKey="placeholder-sizes">
               <rect x="0" y="0" rx="5" ry="5" width="50" height="50" />
               <rect x="65" y="0" rx="5" ry="5" width="50" height="50" />
               <rect x="130" y="0" rx="5" ry="5" width="50" height="50" />
               <rect x="195" y="0" rx="5" ry="5" width="50" height="50" />
            </Placeholder>
         )}

         {!loading && <ul className={styles.list}>{renderSizes()}</ul>}

         {modalVisible && <SizeModal business={business} onClose={() => setModalVisible(false)} />}
      </div>
   );
}

export default SizeList;
