import React from 'react';
import cn from 'classnames';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import styles from './arrow.module.scss';

export default function Arrow(props) {
   const { type, onClick, style } = props;
   const theme = useSelector((state) => state.theme.name);

   const classArrow = cn({
      [styles.arrow]: true,
      [styles[`arrow--${type}`]]: true,
      [styles.arrowCity]: theme === 'city',
   });

   return (
      <button style={style} className={classArrow} onClick={onClick} aria-label={type}>
         {type === 'left' && <MdKeyboardArrowLeft />}
         {type === 'right' && <MdKeyboardArrowRight />}
      </button>
   );
}
