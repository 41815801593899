import { useEffect, useState } from 'react';

function debounce(fn, ms) {
   let timer;
   return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
         timer = null;
         fn.apply();
      }, ms);
   };
}

export default function useSlidesToShow(slides, responsive) {
   const [slidesToShow, setSlidesToShow] = useState(slides);

   const calculateSlides = () => {
      setSlidesToShow(0);
      const width = window.innerWidth;

      for (let x = 0; x < responsive.length; x += 1) {
         if (responsive[x].breakpoint <= width) {
            setSlidesToShow(responsive[x].slidesToShow);
            return;
         }
      }

      setSlidesToShow(slides);
   };

   const debouncedHandleResize = debounce(() => calculateSlides(), 1000);

   useEffect(() => {
      window.addEventListener('resize', debouncedHandleResize);
      calculateSlides();

      return () => {
         window.removeEventListener('resize', debouncedHandleResize);
      };
   }, []);

   return slidesToShow;
}
