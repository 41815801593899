import React, { useState } from 'react';
import Slider from 'Slider';
import { useInView } from 'react-intersection-observer';
import ProductItem from '../../domain/ProductList/ProductItem';
import QuickViewModal from '../QuickViewModal';
import styles from './carousel-products.module.scss';

const responsive = [
   {
      breakpoint: 1240,
      slidesToShow: 4,
   },
   {
      breakpoint: 1024,
      slidesToShow: 3,
   },
   {
      breakpoint: 610,
      slidesToShow: 3,
   },
   {
      breakpoint: 300,
      slidesToShow: 2,
   },
];

export default function CarouselProducts({ title, products, withQuickView = false }) {
   const [modalVisible, setModalVisible] = useState(false);
   const [currentQuickViewProduct, setCurrentQuickViewProduct] = useState({});

   const [viewRef, inView] = useInView({
      threshold: 0,
      rootMargin: '100px 0px 0px 0px',
      triggerOnce: true,
   });

   const showQuickView = (event, product) => {
      let slug = product.url.split('-');
      // Las ultimas 2 posiciones corresponden a ID y COLOR
      const [id, color] = slug.splice(-2, 2);
      slug = slug.join('-'); // El resto se considera el Slug

      event.preventDefault();
      setModalVisible(true);
      setCurrentQuickViewProduct({ id, slug, color });
   };

   const renderProducts = () => {
      return products.map((p) => (
         <div key={p.url} className={styles.item}>
            <ProductItem
               item={p}
               withQuickView={withQuickView}
               handleQuickViewClick={(event) => showQuickView(event, p)}
            />
         </div>
      ));
   };

   const onCloseQuickView = () => {
      setModalVisible(false);
      setCurrentQuickViewProduct({});
   };

   return (
      <div ref={viewRef} className={styles.wrapper}>
         <h3 className={styles.title}>{title}</h3>

         {inView && (
            <Slider className={styles.carousel} responsive={responsive}>
               {renderProducts()}
            </Slider>
         )}
         {modalVisible && (
            <QuickViewModal
               onClose={onCloseQuickView}
               {...currentQuickViewProduct}
               modalVisible={modalVisible}
            />
         )}
      </div>
   );
}
