import Placeholder from 'Placeholder';
import dynamic from 'next/dynamic';

const ProductItem = dynamic(() => import(/* webpackChunkName: "product-item" */ './ProductItem'), {
   loading: () => <Placeholder height={440} />,
});

export default function _(props) {
   return <ProductItem {...props} />;
}
