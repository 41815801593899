import { useEffect } from 'react';

const useMouseOver = (ref, callback) => {
   const handleMouseOver = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
         callback(true);
      }
   };

   const handleMouseOut = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
         callback(false);
      }
   };

   useEffect(() => {
      document.addEventListener('mouseover', handleMouseOver);
      document.addEventListener('mouseout', handleMouseOut);
      return () => {
         document.removeEventListener('mouseover', handleMouseOver);
         document.removeEventListener('mouseout', handleMouseOut);
      };
   }, []);
};

export default useMouseOver;
