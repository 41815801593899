import React, { useRef } from 'react';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { hideModalCart } from '../../../redux/index';
import SizeModalShoes from './SizeModalShoes';
import styles from './size-modal.module.scss';

const SizeModalClothing = dynamic(() => import('./SizeModalClothing'));

export default function SizeModal(props) {
   const modalRef = useRef(null);
   const { business, onClose, className } = props;
   const dispatch = useDispatch();
   const visible = useSelector((state) => state.cart.showModal);

   const classNameContent = cn({
      [styles.modal]: true,
      [className]: !!className,
   });
   useOutsideClick(modalRef, () => {
      if (visible) {
         dispatch(hideModalCart());
      }
   });
   return (
      <div className={styles.container}>
         <div className={classNameContent}>
            <div className={styles.header}>
               <h2 className={styles.title}>¿Cuál es mi talle?</h2>
               <div className={styles.closeButton}>
                  <button className={styles.btnClose} onClick={onClose}>
                     <MdClose size={24} />
                  </button>
               </div>
            </div>
            <div className={styles.content}>
               <p className={styles.subTitle}>
                  Para saber cuál es tu talle, seguí los siguientes pasos
               </p>
               {business === 'Calzado' && <SizeModalShoes />}
               {business === 'Indumentaria' && <SizeModalClothing />}
            </div>
         </div>
      </div>
   );
}
