import React from 'react';
import styles from './size-tooltip.module.scss';

export default function SizeTooltip(props) {
   const { visible, equivalenceTable } = props;

   if (!visible) return null;

   return (
      <div className={styles.container}>
         {equivalenceTable?.ar_size && (
            <span className={styles.big}>{equivalenceTable?.ar_size}</span>
         )}
         {equivalenceTable?.eu_size && (
            <span className={styles.small}>{`EU ${equivalenceTable?.eu_size}`}</span>
         )}
         {equivalenceTable?.uk_size && (
            <span className={styles.small}>{`UK ${equivalenceTable?.uk_size}`}</span>
         )}
         {equivalenceTable?.us_size && (
            <span className={styles.small}>{`USA ${equivalenceTable?.us_size}`}</span>
         )}
         {equivalenceTable?.cm_size && (
            <span className={styles.small}>{`CM ${equivalenceTable?.cm_size}`}</span>
         )}
      </div>
   );
}
