import { useEffect } from 'react';

const useTouchListener = (ref, callback) => {
   const handleTouchStart = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
         callback(true);
      }
   };

   const handleTouchEnd = (e) => {
      if (ref.current && ref.current.contains(e.target)) {
         callback(false);
      }
   };

   useEffect(() => {
      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchend', handleTouchEnd);
      return () => {
         document.removeEventListener('touchstart', handleTouchStart);
         document.removeEventListener('touchend', handleTouchEnd);
      };
   }, []);
};

export default useTouchListener;
