import React, { useState, useEffect } from 'react';
import Modal from 'Modal';
import Head from 'next/head';
import { FaYoutube } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import capitalize from '../../../services/capitalize';
import ImageItem from './ImageItem';
import ImageZoom from './ImageZoom';
import VideoItem from './VideoItem';
import VideoPlayer from './VideoPlayer';
import styles from './gallery.module.scss';

const YTVideo = ({ link, setShowYTVideo }) => {
   return (
      <button
         key="imagen youtubeVideo"
         onClick={() => {
            setShowYTVideo(link);
         }}
         className={styles.youtubeThumbnails}
         aria-label="abrir video de review"
      >
         <FaYoutube color="#FF0000" fontSize={40} />
      </button>
   );
};

function Gallery({ productDetail, modalVisible, isDesktop }) {
   const { embeds } = productDetail.product;

   const { images, name, color } =
      productDetail?.colorSelected || useSelector((state) => state.productDetail.colorSelected);
   const [zoomImage, setZoomImage] = useState(images[0]?.zoom.url);
   const [showVideo, setshowVideo] = useState(false);
   const [video, setVideo] = useState(null);

   useEffect(() => {
      setZoomImage(images[0]?.zoom.url);
   }, [images]);

   // al hacer click en un thumbnail
   // mostramos la imagen grande
   const handleClickImage = (image) => {
      setshowVideo(false);
      setZoomImage(image);
   };

   const handleClickVideo = (image) => {
      setVideo(image?.zoom?.url);
      setshowVideo(true);
   };

   const [showYTVideo, setShowYTVideo] = useState('');

   const renderThumbnailList = () => {
      const returnComponents = [];
      embeds.youtube?.forEach((element) => {
         returnComponents.push(<YTVideo link={element} setShowYTVideo={setShowYTVideo} />);
      });
      embeds.render?.forEach((element) => {
         returnComponents.push(
            <ImageItem
               key={element}
               thumbnail="/assets/360.jpg"
               zoom={element}
               onClick={handleClickImage}
               alt={element}
            />,
         );
      });

      returnComponents.push(
         images.map((image, i) => {
            if (image?.thumbnail?.ext === 'webm' || image?.thumbnail?.ext === 'mp4') {
               return (
                  <VideoItem key={image.thumbnail.url} onClick={() => handleClickVideo(image)} />
               );
            }
            return (
               <ImageItem
                  key={image.thumbnail.url}
                  thumbnail={image.thumbnail.url}
                  zoom={image.zoom.url}
                  onClick={handleClickImage}
                  alt={`${name} - ${capitalize(color.toLowerCase())} - ${i + 1}`}
               />
            );
         }),
      );
      return returnComponents;
   };

   return (
      <div className={styles.wrapper}>
         <Head>
            <link rel="stylesheet" type="text/css" href="/react-inner-image-zoom.min.css" />
         </Head>
         <div className={styles.thumbnailList}>{renderThumbnailList()}</div>

         {!showVideo &&
            (zoomImage?.includes('amazonaws') ? (
               <ImageZoom
                  src={zoomImage}
                  modalVisible={modalVisible}
                  alt={`${name} - ${capitalize(color.toLowerCase())}`}
               />
            ) : (
               <iframe
                  width="100%"
                  height="82vw"
                  src={zoomImage}
                  style={{ border: 'none', height: isDesktop ? '35vw' : '82vw' }}
                  title="Modelo 3D"
               />
            ))}

         {showVideo && <VideoPlayer src={video} />}
         {showYTVideo !== '' && (
            <Modal
               onClose={() => setShowYTVideo('')}
               closeColor="white"
               modalStyles={{
                  backgroundColor: 'transparent',
                  justifyContent: 'center',
                  margin: 0,
                  padding: 0,
               }}
            >
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div
                     style={{
                        height: isDesktop ? '70vh' : '55vh',
                        alignSelf: 'center',
                        width: isDesktop ? '50vw' : '100%',
                     }}
                  >
                     <iframe
                        width="100%"
                        height="100%"
                        style={{ borderRadius: '30px' }}
                        frameBorder={0}
                        src={showYTVideo}
                        title="Titulo"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                     />
                  </div>
               </div>
            </Modal>
         )}
      </div>
   );
}

export default Gallery;
