/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import PropTypes from 'prop-types';
import useMouseOver from '../../hooks/useMouseOver';
import useSlidesToShow from '../../hooks/useSlidesToShow';
import useTouchListener from '../../hooks/useTouchListener';
import Arrow from './Arrow/index';
import styles from './slider.module.scss';

const visualizateArrows = (arrows, children) => {
   const isFull = useMediaQuery('(max-width: 1000px)');
   return arrows && (isFull || children.length > 4);
};

export default function Slider(props) {
   const { children, className, interval, responsive, arrows } = props;
   const [containerWidth, setContainerWidth] = useState(0);
   const [isAutoplay, setAutoplay] = useState(true);
   const [index, setIndex] = useState(0);
   const containerRef = useRef(null);
   const slidesToShow = useSlidesToShow(props.slidesToShow, responsive);
   const itemWidth = containerWidth / slidesToShow;
   const contentWidth = children.length * itemWidth;
   const totalIndex = Math.ceil(children.length / slidesToShow) - 1;

   useMouseOver(containerRef, (isHover) => {
      setAutoplay(!isHover);
   });

   useTouchListener(containerRef, () => {
      setAutoplay(false);
   });

   useEffect(() => {
      if (containerRef.current) {
         setContainerWidth(containerRef.current.offsetWidth);
         containerRef.current.scrollTo(0, 0);
      }
   }, [containerRef, slidesToShow]);

   useEffect(() => {
      if (containerRef.current && isAutoplay) {
         const int = setInterval(() => {
            setIndex((index) => {
               if (index + 1 > totalIndex) return 0;
               return index + 1;
            });
         }, interval);

         return () => {
            clearInterval(int);
         };
      }

      return () => {};
   }, [containerRef, isAutoplay, totalIndex]);

   const transition = () => {
      let value = itemWidth * slidesToShow * index;

      if (value < 0) {
         value = 0;
      }

      containerRef.current.scrollTo({
         left: value,
         behavior: 'smooth',
      });
   };

   useEffect(() => {
      transition();
   }, [index]);

   const handleClickArrow = (type) => {
      switch (type) {
         case 'next':
            setIndex((index) => {
               if (index + 1 > totalIndex) return 0;
               return index + 1;
            });
            break;

         case 'back':
            setIndex((index) => {
               if (index === 0) return totalIndex;
               return index - 1;
            });
            break;

         default:
            break;
      }

      setAutoplay(false);
   };

   const renderItems = () => {
      return children.map((x, i) => (
         <div key={i} className={styles.item} style={{ width: itemWidth }}>
            {x}
         </div>
      ));
   };

   return (
      <div className={className}>
         <div ref={containerRef} className={styles.container}>
            <div className={styles.content} style={{ width: contentWidth }}>
               {containerRef.current && renderItems()}
            </div>
         </div>

         {visualizateArrows(arrows, children) && (
            <>
               <Arrow onClick={() => handleClickArrow('back')} type="left" />
               <Arrow onClick={() => handleClickArrow('next')} type="right" />
            </>
         )}
      </div>
   );
}

Slider.propTypes = {
   interval: PropTypes.number,
   slidesToShow: PropTypes.number,
   responsive: PropTypes.arrayOf(PropTypes.object),
   arrows: PropTypes.bool,
};

Slider.defaultProps = {
   interval: 4000,
   slidesToShow: 1,
   arrows: true,
   responsive: [
      {
         breakpoint: 1000,
         slidesToShow: 4,
      },
   ],
};
