/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import React, { useEffect, useState } from 'react';
import Fade from 'Fade';
import Placeholder from 'Placeholder';
import dynamic from 'next/dynamic';
import styles from './image-zoom.module.scss';

const InnerImageZoom = dynamic(() =>
   import(/* webpackChunkName: "react-inner-image-zoom" */ 'react-inner-image-zoom'),
);

function ImageZoom(props) {
   const { src, modalVisible, alt } = props;
   const [loading, setLoading] = useState(true);

   // cuando la imagen termina de descargar
   // ocultamos el placeholder
   useEffect(() => {
      setLoading(true);

      // creamos una instancia de Image
      const image = new Image();

      // observamos el evento onload
      // para ver cuando termina de descargar
      image.onload = () => {
         setLoading(false);
      };

      // le cargamos la imagen a la instancia
      // y va a empezar a descargarse
      image.src = src;
   }, [src]);

   return (
      <div className={modalVisible ? styles.onModal : styles.wrapper}>
         <InnerImageZoom
            src={src}
            className={styles.zoom}
            fullscreenOnMobile
            imgAttributes={{ alt }}
         />

         {/* el placeholder tiene fadeOut cuando termina de descargar */}
         <Fade show={loading} className={styles.placeholder}>
            <Placeholder />
         </Fade>
      </div>
   );
}

export default ImageZoom;
