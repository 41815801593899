/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { MdClose } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import useMouseOver from '../../../../hooks/useMouseOver';
import { setVariant } from '../../../../redux/productDetail/productDetailActions';
import SizeTooltip from '../SizeTooltip';
import styles from './size-item.module.scss';

function SizeItem({ variant, customSetVariant, productDetail, variantSelected }) {
   const ref = useRef(null);
   const dispatch = useDispatch();
   const selected = (variantSelected?.id || productDetail.variantSelected?.id) === variant.id;
   const hasStock = variant.count_on_hand > 0;
   const [showTooltip, setShowTooltip] = useState(false);

   useMouseOver(ref, (isOver) => {
      setShowTooltip(isOver);
   });

   const handleClick = () => {
      if (hasStock) {
         if (customSetVariant) {
            customSetVariant(variant);
         } else {
            dispatch(setVariant(variant));
         }
      }
   };

   const classItem = cn({
      [styles.item]: true,
      [styles['item--active']]: selected,
      [styles['item--disabled']]: !hasStock,
   });

   return (
      <li ref={ref} title={variant.size} className={classItem} onClick={handleClick}>
         {!hasStock && <MdClose className={styles.icon} />}
         {variant.size}

         <SizeTooltip visible={showTooltip} equivalenceTable={variant.equivalence_table} />
      </li>
   );
}

export default SizeItem;
