import styles from './video-player.module.scss';

export default function VideoPlayer(props) {
   const { src } = props;

   return (
      <video loop autoPlay muted src={src} className={styles.container} playsInline>
         <source src={src} type='video/webm; codecs="vp8, vorbis"' />
      </video>
   );
}
