/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import Fade from 'Fade';
import Placeholder from 'Placeholder';
import ImageNextJS from 'next/image';
import PropTypes from 'prop-types';
import styles from './image-item.module.scss';

function ImageItem(props) {
   const { thumbnail, zoom, onClick, alt } = props;
   const [loading, setLoading] = useState(true);

   // cuando la imagen termina de cargar
   // ocultamos el placeholder
   useEffect(() => {
      // creamos una imagen
      const image = new Image();

      // vemos cuando termina de descargar
      image.onload = () => {
         setLoading(false);
      };

      // le asignamos la imagen a la instancia
      // comienza a descargar
      image.src = `/_next/image?url=${encodeURIComponent(thumbnail)}&w=96&q=50`;
   }, []);

   return (
      <div onClick={() => onClick(zoom)} className={styles.wrapper}>
         <Fade show={loading} className={styles.placeholder}>
            <Placeholder />
         </Fade>

         <ImageNextJS
            src={thumbnail}
            width={80}
            height={80}
            quality={50}
            className={styles.image}
            alt={alt}
         />
      </div>
   );
}

ImageItem.propTypes = {
   thumbnail: PropTypes.string.isRequired,
   zoom: PropTypes.string.isRequired,
   onClick: PropTypes.func.isRequired,
};

export default ImageItem;
