/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import PropTypes from 'prop-types';
import { MdPlayCircleFilled } from 'react-icons/md';
import styles from './video-item.module.scss';

function VideoItem(props) {
   const { onClick } = props;

   return (
      <div onClick={() => onClick()} className={styles.container}>
         <MdPlayCircleFilled size={30} />
      </div>
   );
}

VideoItem.propTypes = {
   onClick: PropTypes.func.isRequired,
};

export default VideoItem;
