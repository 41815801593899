import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import Badge from 'Badge/index';
import Brand from 'Brand';
import Spinner from 'Spinner';
import { useRouter } from 'next/router';
import { GiClick } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../api';
import Gallery from '../../domain/ProductDetail/Gallery/index';
import Price from '../../domain/ProductDetail/Price/index';
import SizeList from '../../domain/ProductDetail/SizeList/index';
import ProductHeader from '../../domain/ProductHeader/index';
import useTrackingQueue from '../../hooks/useTrackingQueue';
import { addToCart } from '../../redux';
import createUUID from '../../services/createUUID';
import generateHrefLink from '../../services/page/generateLinkHref';
import themes from '../../themes';
import Button from '../Button/index';
import Modal from '../Modal/index';
import styles from './quick-view-modal.module.scss';

const QuickViewModal = (props) => {
   const { executeEvent } = useTrackingQueue();
   const isMobile = useMediaQuery('(max-width: 500px)');
   const cart = useSelector((state) => state.cart);
   const dispatch = useDispatch();
   const { onClose, id, slug, color, modalVisible, noShowInstallments } = props;
   const router = useRouter();
   const [productDetail, setProductDetail] = useState({});
   const [productDetailLoading, setProductDetailLoading] = useState(true);
   const [variantSelected, setVariantSelected] = useState(null);
   const theme = useSelector((state) => state.theme.name);

   const { product } = productDetail;

   const getProductDetail = async () => {
      const eventId = createUUID();
      const { data, success } = await API.products.getByID(id, eventId);
      const installments = await API.installments.getAllPromos(
         process.env.NEXT_PUBLIC_STORE_ID,
         id,
      );
      if (success) {
         const colorSelected = data.colors.find((c) => c.id === Number(color));

         if (colorSelected === undefined) {
            throw new Error(`No hay colores. ID: ${id} / COLOR: ${color} / SLUG: ${slug}`);
         }

         setProductDetail({
            id,
            slug,
            product: data,
            colorSelected,
            quantity: 0,
            installments: installments.data,
         });

         setProductDetailLoading(false);
      }
   };

   useEffect(() => {
      getProductDetail();
   }, []);

   const handleClickCart = () => {
      const eventId = createUUID();
      const formattedProductDetail = { quantity: 1, variantSelected };
      dispatch(addToCart(isMobile, formattedProductDetail, onClose, eventId));
      executeEvent('addToCart', { productDetail, eventId });
   };

   const renderContent = () => {
      if (productDetailLoading) {
         return (
            <div className={styles.spinner}>
               <Spinner />
            </div>
         );
      }

      const redirectToHandler = async () => {
         document.cookie = `idColor=${color}; max-age=${7200}; path=/`;
         await router.push(generateHrefLink(`${slug}-${id}`, router));
         dispatch(onClose);
      };
      return (
         <>
            <Gallery productDetail={productDetail} modalVisible={modalVisible} />
            <div className={styles.wrapperTop}>
               <div className={styles.productBrandContainer}>
                  <ProductHeader
                     colorSelected={productDetail?.colorSelected}
                     gender={product.gender}
                     sport={product.sport}
                     featured={product.featured}
                  />
                  <span>
                     {product.brand_icon && (
                        <Brand brandName={product.brand_name} brandIcon={product.brand_icon.url} />
                     )}
                  </span>
               </div>
               <div className={styles.sku}>SKU {product.sku}</div>
               <Price noShowInstallments={noShowInstallments} productDetail={productDetail} />
               <Badge
                  backgroundColorLeft={themes[theme]['color-prod-detail-badge-bg']}
                  backgroundColorRight={themes[theme]['color-prod-detail-badge-bg']}
                  color={themes[theme]['color-prod-detail-badge-text']}
                  icon={<GiClick />}
               >
                  Para más colores ingresá a Ver detalle
               </Badge>
               {/* <ColorList colors={product.colors} /> */}
               <SizeList
                  productDetail={productDetail}
                  customSetVariant={setVariantSelected}
                  variantSelected={variantSelected}
               />
               <div className={styles.buttonContainer}>
                  <Button
                     label="Agregar a carrito"
                     className={styles.button}
                     disabled={!variantSelected}
                     onClick={handleClickCart}
                     loading={cart.loading}
                  />
                  <Button
                     className={styles.detailButton}
                     label="Ver detalle"
                     onClick={redirectToHandler}
                  />
               </div>
            </div>
         </>
      );
   };

   return (
      <Modal className={styles.modalStyle} onClose={onClose}>
         <div className={styles.modalContainer}>{renderContent()}</div>
      </Modal>
   );
};

export default QuickViewModal;
