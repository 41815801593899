import React from 'react';
import Image from 'next/image';
import styles from './brand.module.scss';

function Brand(props) {
   const { brandName, brandIcon } = props;
   return (
      <Image
         src={brandIcon}
         alt={brandName}
         className={styles.image}
         width={80}
         height={40}
         quality={25}
         loading="eager"
         translate="no"
      />
   );
}

export default Brand;
